import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  CogIcon,
  LockClosedIcon,
  ServerIcon,
  ShieldCheckIcon,
} from '@heroicons/react/24/outline'

const features = [
  {
    name: 'Monthly Bookkeeping',
    description: 'Accurate and timely record-keeping to manage your financial transactions each month.',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'Financial Statements',
    description: 'Preparation of detailed financial statements to help you understand your business performance.',
    icon: LockClosedIcon,
  },
  {
    name: 'Tax Planning and Preparation',
    description: 'Strategic tax planning and preparation services to minimize liabilities and ensure compliance.',
    icon: ArrowPathIcon,
  },
  {
    name: 'IRS Audit Representation',
    description: 'Expert representation during IRS audits to protect your interests and ensure a fair outcome.',
    icon: ShieldCheckIcon,
  },
  {
    name: 'Invoicing and Collections',
    description: 'Streamlined invoicing and collections process to ensure timely payments and improved cash flow.',
    icon: CogIcon,
  },
  {
    name: 'Business Plan Preparation',
    description: 'Professional preparation of comprehensive business plans to guide your growth and secure funding.',
    icon: ServerIcon,
  },
  {
    name: 'Controllership',
    description: 'Professional oversight of your financial operations to ensure efficient and effective management.',
    icon: ShieldCheckIcon,
  },
  {
    name: 'Account Reconciliation',
    description: 'Regular reconciliation of accounts to ensure accuracy and identify any discrepancies.',
    icon: CogIcon,
  },
  {
    name: 'Bill Payment',
    description: 'Efficient bill payment services to manage your payables and maintain good vendor relationships.',
    icon: ServerIcon,
  },
]

export default function Content() {
  return (
    <div id="services" className="relative bg-wavy-gradient-blue py-24 sm:pt-24 sm:pb-0 lg:pb-8 lg:py-24">
      <div className="mx-auto max-w-7xl px-6 text-center">
        {/* Section Heading */}
        <h2 className="text-lg font-semibold text-blue-600 uppercase tracking-wide">
          Expert Financial Services
        </h2>
        <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          Comprehensive Solutions for Your Business
        </p>
        <p className="mx-auto mt-6 max-w-2xl text-lg text-gray-600">
          Our expert team provides tailored financial services to ensure your business runs smoothly and efficiently. From accurate bookkeeping to strategic tax planning, we've got you covered.
        </p>
      </div>

      {/* Features Grid */}
      <div className="mt-16 mx-auto max-w-6xl grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 px-6 lg:px-0">
        {features.map((feature) => (
          <div
            key={feature.name}
            className="relative flex flex-col items-center text-center bg-white rounded-2xl shadow-lg p-8 transition-all duration-300 transform hover:-translate-y-2 hover:shadow-2xl"
          >
            {/* Feature Icon */}
            <div className="flex items-center justify-center w-16 h-16 rounded-xl bg-blue-600 text-white mb-4 shadow-md">
              <feature.icon className="h-8 w-8" aria-hidden="true" />
            </div>

            {/* Feature Name */}
            <h3 className="text-xl font-semibold text-gray-900">{feature.name}</h3>

            {/* Feature Description */}
            <p className="mt-3 text-gray-600 text-md">{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  )
}
