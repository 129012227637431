import React from 'react';
import { Route, Routes, useLocation  } from 'react-router-dom';
import Home from './components/home/Home.jsx';
import Contact from './components/contact/Contact.js';
import About from './components/about/About.js';
import './components/global/globals.css';
import PrivacyPolicy from './components/global/Privacy.jsx';
import ScrollToTopButton from './components/global/ScrollToTopButton';

function App() {
    const location = useLocation();

    return (
        <div>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/about" element={<About />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
            </Routes>

            {/* Scroll Button for Navigation */}
            {!(location.pathname.startsWith('/individual')) && <ScrollToTopButton showNote={false} />}
        </div>
    );
}

export default App;
