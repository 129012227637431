import React from "react";
import { InboxIcon, SparklesIcon, DocumentIcon } from '@heroicons/react/24/outline';

export default function LongContent() {
  return (
    <div className="relative overflow-hidden bg-white lg:pt-4 lg:py-32">
      <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
        <div className="px-6 lg:px-8 text-center">
          <h1 className="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">Expert Financial Management Services</h1>
          <p className="mx-auto mt-5 max-w-prose text-lg mt-4 text-lg text-gray-600 text-center pb-16">
            With over 50 years of combined experience, we offer expert financial services, including tax planning, bookkeeping, payroll, and business financing. Our user-friendly portal provides seamless record management, secure file storage, and an efficient messaging center.
          </p>
        </div>
      </div>
      <div className="relative ">
        <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
          <div className="mx-auto max-w-xl px-6 lg:mx-0 lg:max-w-none lg:px-0 lg:py-16">
            <div>
              <div>
                <span className="flex h-12 w-12 items-center justify-center rounded-2xl bg-blue-600 shadow-sm">
                  <InboxIcon aria-hidden="true" className="h-8 w-8 text-white" />
                </span>
              </div>
              <div className="mt-6 bg-wavy-gradient-blue">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">Seamless Secure Storage</h2>
                <p className="mt-4 text-lg text-gray-600">
                  Welcome to the future of secure file management. Our customer portal leverages AWS and multi-factor authentication to ensure your sensitive documents are always protected. Forget the hassle of digging through stacks of paperwork; with us, your W2s, 1099s, and other critical files are just a click away, stored safely and accessed effortlessly. This secure storage is crucial for quick tax and financial advisory, enabling our experts to provide timely and accurate services.
                </p>
                <div className="mt-6">
                  <a
                    href="/contact"
                    className="inline-flex rounded-3xl bg-blue-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-blue-600 hover:bg-blue-700 hover:ring-blue-700"
                  >
                    Explore Now
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="-mr-48 pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0">
              <img
                alt="Secure file storage interface"
                src="https://s3.us-east-2.amazonaws.com/www.soffinancialservices.com/site-images/Screenshot_23-7-2024_224258_localhost.jpeg"
                className="w-full rounded-3xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-24 bg-wavy-gradient-blue">
        <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
          <div className="mx-auto max-w-xl px-6 lg:col-start-2 lg:mx-0 lg:max-w-none lg:px-0 lg:py-32">
            <div>
              <div>
                <span className="flex h-12 w-12 items-center justify-center rounded-2xl bg-blue-600 shadow-sm">
                  <SparklesIcon aria-hidden="true" className="h-8 w-8 text-white" />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">Instant Updates and Communication</h2>
                <p className="mt-4 text-lg text-gray-600">
                  Stay in the loop with real-time updates and direct communication through our innovative messaging center. Our platform ensures you receive timely updates from our financial experts, keeping you informed on the progress of your services and any important notes. Experience the peace of mind that comes with constant connectivity and transparency.
                </p>
                <div className="mt-6">
                  <a
                    href="/contact"
                    className="inline-flex rounded-3xl bg-blue-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-blue-600 hover:bg-blue-700 hover:ring-blue-700"
                  >
                    Learn More
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:col-start-1 lg:mt-0">
            <div className="-ml-48 pr-6 md:-ml-16 lg:relative lg:m-0 lg:h-full lg:px-0">
              <img
                alt="Real-time updates interface"
                src="https://s3.us-east-2.amazonaws.com/www.soffinancialservices.com/site-images/Screenshot_23-7-2024_19410_localhost.jpeg"
                className="w-full h-auto rounded-3xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-24">
        <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
          <div className="mx-auto max-w-xl px-6 lg:mx-0 lg:max-w-none lg:px-0 lg:py-16">
            <div>
              <div>
                <span className="flex h-12 w-12 items-center justify-center rounded-2xl bg-blue-600 shadow-sm">
                  <DocumentIcon aria-hidden="true" className="h-8 w-8 text-white" />
                </span>
              </div>
              <div className="mt-6 bg-wavy-gradient-blue">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">Streamlined File Transfer and Sharing</h2>
                <p className="mt-4 text-lg text-gray-600">
                  Our platform makes file transfer and sharing a breeze. Quickly and securely share financial documents with our team for prompt assistance with monthly bookkeeping, tax preparation, and financial statement generation. No more sifting through piles of paperwork – everything is organized and accessible in one place.
                </p>
                <div className="mt-6">
                  <a
                    href="/contact"
                    className="inline-flex rounded-3xl bg-blue-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-blue-600 hover:bg-blue-700 hover:ring-blue-700"
                  >
                    Discover More
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="-mr-48 pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0">
              <img
                alt="File transfer interface"
                src="https://s3.us-east-2.amazonaws.com/www.soffinancialservices.com/site-images/1040.PNG"
                className="w-full rounded-3xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
