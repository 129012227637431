import { CloudIcon, ClockIcon, UserGroupIcon } from '@heroicons/react/24/outline'

const features = [
  {
    name: 'Cloud-based File Sharing',
    description:
      'Our platform offers secure cloud-based file sharing, ensuring your important documents like W-2s and 1099s are always accessible when you need them. With AWS security and multi-factor authentication, your data is protected.',
    href: '/contact',
    icon: CloudIcon,
  },
  {
    name: 'Real-time Updates',
    description:
      'Receive real-time updates and notifications from our expert team on the progress of your services. Stay informed with the latest data on your financial and tax matters, ensuring complete transparency.',
    href: '/contact',
    icon: ClockIcon,
  },
  {
    name: 'Expert Tax and Finance Professionals',
    description:
      'Our team of seasoned professionals offers tailored services to meet your unique business needs. From tax preparation to financial planning, we provide personalized solutions to help your business thrive.',
    href: '/contact',
    icon: UserGroupIcon,
  },
]

export default function Example() {
  return (
    <div className="relative bg-wavy-gradient-blue py-16 sm:py-24">
      <div className="mx-auto max-w-7xl px-6 text-center">
        {/* Section Heading */}
        <h2 className="text-lg font-semibold text-blue-600 uppercase tracking-wide">
          Innovative Tech Solutions
        </h2>
        <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          Our Advanced Client Portal
        </p>
        <p className="mx-auto mt-6 max-w-2xl text-lg text-gray-600">
          Experience seamless financial management with our innovative platform designed for secure file sharing, real-time updates, and expert support tailored to your business needs.
        </p>
      </div>

      {/* Image Section */}
      <div className="relative overflow-hidden pt-16">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <img
            alt="App screenshot"
            src="https://s3.us-east-2.amazonaws.com/www.soffinancialservices.com/site-images/Screenshot_23-7-2024_195141_localhost.jpeg"
            width={2432}
            height={1442}
            className="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10"
          />
          <div aria-hidden="true" className="relative">
            <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]" />
          </div>
        </div>
      </div>

      {/* Features Section - Raised Block Style */}
      <div className="mt-16 mx-auto max-w-6xl grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 px-6 lg:px-0">
        {features.map((feature) => (
          <div
            key={feature.name}
            className="relative flex flex-col items-center text-center bg-white rounded-2xl shadow-lg p-8 transition-all duration-300 transform hover:-translate-y-2 hover:shadow-2xl"
          >
            {/* Feature Icon */}
            <div className="flex items-center justify-center w-16 h-16 rounded-xl bg-blue-600 text-white mb-4 shadow-md">
              <feature.icon className="h-8 w-8" aria-hidden="true" />
            </div>

            {/* Feature Name */}
            <h3 className="text-xl font-semibold text-gray-900">{feature.name}</h3>

            {/* Feature Description */}
            <p className="mt-3 text-gray-600 text-md">{feature.description}</p>

            {/* Learn More Button */}
            <a
              href={feature.href}
              className="mt-5 inline-block text-sm font-semibold text-blue-600 hover:text-blue-500 transition"
            >
              Learn more <span aria-hidden="true">→</span>
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}
