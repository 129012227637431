import React, { useState, useEffect } from 'react';
import { FaArrowUp } from 'react-icons/fa';

export default function ScrollToTopButton({ showNote }) {
  const [isVisible, setIsVisible] = useState(false);

  // Show the button when scrolling down
  const toggleVisibility = () => {
    if (window.pageYOffset > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <div>
      {isVisible && (
        <div className="fixed bottom-4 right-4 flex flex-col items-center z-50">
          <div
            onClick={scrollToTop}
            className="p-3 rounded-full text-white cursor-pointer bg-gradient-to-r from-blue-500 to-blue-600 hover:bg-gradient-to-r hover:from-logo-light-blue hover:to-logo-light-blue shadow-lg"
          >
            <FaArrowUp className="h-6 w-6" />
          </div>

          {showNote && (
            <div
              className="mt-2 px-4 py-2 bg-gray-700 text-white text-xs rounded-md"
              style={{ pointerEvents: 'none' }} 
            >
              Scroll up for more courses
            </div>
          )}
        </div>
      )}
    </div>
  );
}
